import type { DateTimeOptions, Locale, NumberOptions } from 'vue-i18n';

export type DateFormatKeys = 'short' | 'long'; // Les clés de format

const datetimeFormats: Record<Locale, Record<DateFormatKeys, DateTimeOptions>> = {
    fr: {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric'
        }
    },
    en: {
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric'
        }
    }
};

export type NumberFormatKeys = 'currency' | 'decimal' | 'percent';

const numberFormats: Record<Locale, Record<NumberFormatKeys, NumberOptions>> = {
    fr: {
        currency: {
            style: 'currency',
            currency: 'EUR'
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2
        },
        percent: {
            style: 'percent',
            maximumFractionDigits: 2
        }
    },
    en: {
        currency: {
            style: 'currency',
            currency: 'EUR'
        },
        decimal: {
            style: 'decimal',
            minimumFractionDigits: 2
        },
        percent: {
            style: 'percent',
            maximumFractionDigits: 2
        }
    }
};

export default defineI18nConfig(() => ({
    legacy: false,
    numberFormats,
    datetimeFormats
}));
